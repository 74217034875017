@use 'sass:math';
.container {
    margin: 0 auto;
    max-width: 1280px;
    width: 90%;
}
@media #{$medium-and-up} {
    .container {
        width: 85%;
    }
}
@media #{$large-and-up} {
    .container {
        width: 70%;
    }
}
.col .row {
    margin-left: (-1 * math.div($gutter-width, 2));
    margin-right: (-1 * math.div($gutter-width, 2));
}

.section {
    padding-top: 1rem;
    padding-bottom: 1rem;

    &.no-pad {
        padding: 0;
    }
    &.no-pad-bot {
        padding-bottom: 0;
    }
    &.no-pad-top {
        padding-top: 0;
    }
}

// Mixins to eliminate code repitition
@mixin reset-offset {
    margin-left: auto;
    left: auto;
    right: auto;
}
@mixin grid-classes($size, $i, $perc) {
    &.offset-#{$size}#{$i} {
        margin-left: $perc;
    }
    &.pull-#{$size}#{$i} {
        right: $perc;
    }
    &.push-#{$size}#{$i} {
        left: $perc;
    }
}

.row {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 20px;

    // Clear floating children
    &:after {
        content: "";
        display: table;
        clear: both;
    }

    .col {
        float: left;
        box-sizing: border-box;
        padding: 0 math.div($gutter-width, 2);
        min-height: 1px;

        &[class*="push-"],
        &[class*="pull-"] {
            position: relative;
        }

        $i: 1;
        @while $i <= $num-cols {
            $devidedval: math.div($num-cols, $i);
            $perc: unquote(math.div(100, $devidedval) + "%");
            &.s#{$i} {
                width: $perc;
                @include reset-offset;
            }
            $i: $i + 1;
        }

        $i: 1;
        @while $i <= $num-cols {
            $devidedval: math.div($num-cols, $i);
            $perc: unquote(math.div(100, $devidedval) + "%");
            @include grid-classes("s", $i, $perc);
            $i: $i + 1;
        }

        @media #{$medium-and-up} {
            $i: 1;
            @while $i <= $num-cols {
                $devidedval: math.div($num-cols, $i);
                $perc: unquote(math.div(100, $devidedval) + "%");
                &.m#{$i} {
                    width: $perc;
                    @include reset-offset;
                }
                $i: $i + 1;
            }

            $i: 1;
            @while $i <= $num-cols {
                $devidedval: math.div($num-cols, $i);
                $perc: unquote(math.div(100, $devidedval) + "%");
                @include grid-classes("m", $i, $perc);
                $i: $i + 1;
            }
        }

        @media #{$large-and-up} {
            $i: 1;
            @while $i <= $num-cols {
                $devidedval: math.div($num-cols, $i);
                $perc: unquote(math.div(100, $devidedval) + "%");
                &.l#{$i} {
                    width: $perc;
                    @include reset-offset;
                }
                $i: $i + 1;
            }

            $i: 1;
            @while $i <= $num-cols {
                $devidedval: math.div($num-cols, $i);
                $perc: unquote(math.div(100, $devidedval) + "%");
                @include grid-classes("l", $i, $perc);
                $i: $i + 1;
            }
        }

        @media #{$extra-large-and-up} {
            $i: 1;
            @while $i <= $num-cols {
                $devidedval: math.div($num-cols, $i);
                $perc: unquote(math.div(100, $devidedval) + "%");
                &.xl#{$i} {
                    width: $perc;
                    @include reset-offset;
                }
                $i: $i + 1;
            }

            $i: 1;
            @while $i <= $num-cols {
                $devidedval: math.div($num-cols, $i);
                $perc: unquote(math.div(100, $devidedval) + "%");
                @include grid-classes("xl", $i, $perc);
                $i: $i + 1;
            }
        }
    }
}
