@import '_colors';
@import '_variables.scss';
@import '_grid';
@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,700');

html {
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
}

html, body {
    min-height: 100vh;
    margin: 0px;
}

/* Weird ng-component fix */ 
ng-component {
    width: 100%;
    background-color: primary-palette(100-white);
}

/* iFrame fix for adal iframe */
iframe{ 
    border: 0!important;
}

.fill-remaining-space {
    flex: 1 1 auto;
}

@for $i from 1 through 8 {
    .flex-#{$i} {
        flex: $i!important;
    }
}

@for $i from 1 through 4 {
    $x: #{$i * 25};
    .w-#{$x} {
        width: unquote($x + "% " + !important);
    }
    .h-#{$x} {
        height: unquote($x + "% " + !important);
    }
}

@for $i from 0 through 10 {
    $x: #{$i * 5}px!important;

    .mt-#{$i * 5} {
        margin-top: $x
    }

    .mb-#{$i * 5} {
        margin-bottom: $x
    }

    .mr-#{$i * 5} {
        margin-right: $x
    }

    .ml-#{$i * 5} {
        margin-left: $x
    }

    .m-#{$i * 5} {
        margin: $x
    }

    .pt-#{$i * 5} {
        padding-top: $x
    }

    .pb-#{$i * 5} {
        padding-bottom: $x
    }

    .pr-#{$i * 5} {
        padding-right: $x
    }

    .pl-#{$i * 5} {
        padding-left: $x
    }

    .p-#{$i * 5} {
        padding: $x
    }
}

/*******************
  Utility Classes
*******************/

.border-box{
    box-sizing: border-box;
}

.center, .center-align {
    text-align: center;
}

.text-right {
    text-align: right;
}

.left {
    float: left !important;
}

.right {
    float: right !important;
}

.pointer {
    cursor: pointer;
}

.position-inherit{
    position: inherit!important;
}

.min-height-inherit{
    min-height: inherit!important;
}

.hover:hover{
    background-color: primary-palette(100-dark-gray);
}

.space-between{
    justify-content: space-between;
}

.block {
    display: block;
}

.flex {
    display: flex;
}

.flex-center{
    justify-content: center;
    align-items: center;
}

.flex-column { 
    flex-direction: column;
}

.noselect {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

/*******************
  Custom Classes
*******************/

.mat-chip-active {
    background-color: signal-color-palette(100-green)!important;
}
.mat-chip-inactive {
    background-color: primary-palette(100-coral)!important;
}